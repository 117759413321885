<template>
  <Fieldset legend="ถอนสิทธิ์การใช้งาน">
    <Message severity="error" style="font-size: 18px"
      >การถอนสิทธิ์การใช้งานข้อมูลของท่านจะถูกลบออกจากระบบ
      และจะไม่สามารถเข้าใช้งานระบบได้อีก</Message
    >
    <Button
      label="ถอนสิทธิ์การใช้งาน"
      icon="pi pi-user-minus"
      class="p-button-danger p-fluid"
      @click="revokeUser"
      :disabled="isDisabled"
    />
  </Fieldset>
</template>
<script>
import * as moment from 'moment'
export default {
  name: 'revokeUser',
  layout: 'Private',
  computed: {
    isDisabled() {
      const consent = this.$store.getters['oauth/consent']
      return consent?.revokeDtm
    }
  },
  methods: {
    revokeUser() {
      //confirm before  save
      this.$confirm.require({
        ...this.$primevue.config.locale,
        header: 'ยืนยันการถอนสิทธิ์การใช้งานระบบ',
        message:
          'ยืนยันการถอนสิทธิ์การใช้งานระบบ ใช่หรือไม่ กด ตกลง เพื่อยืนยันการถอนสิทธิ์ หรือ ยกเลิก เพื่อยกเลิกการทำรายการ',
        icon: 'pi pi-user-minus',
        reject: async () => {
          const { dateFormatGet, dateFormatSave } = this.$primevue.config.locale
          const usr = this.$store.getters['oauth/session']
          const consent = this.$store.getters['oauth/consent']
          const { status, code, data } = await this.$store.dispatch(
            'pdpa/revokeUserConsent',
            {
              ...consent,
              consentStatus: 'R',
              revokeDtm: moment().format(dateFormatSave),
              user: usr
            }
          )
          if (code == 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'สถานะ ถอนสิทธิ์การใช้งาน',
              detail: 'ถอนสิทธิ์การใช้งานจากระบบสำเร็จ',
              life: 3000
            })
            this.$store.commit('oauth/clearSession')
            setTimeout(() => {
              this.$router.push({ path: '/' })
            }, 3000)
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'สถานะ ถอนสิทธิ์การใช้งาน',
              detail: 'ระบบไม่สามารถให้บริการได้ กรุณาติดต่อเจ้าหน้าที่ดูแล',
              life: 3000
            })
          }
        }
      })
    }
  }
}
</script>

<style>
</style>